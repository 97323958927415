import { ssrBackedReactContextFeaturesStoreFactory as featuresStoreFactory } from "@asosteam/asos-web-toggle-point-features";
import logger from "./lib/logger";
import { LOG_LEVEL_WARN } from "./lib/logger/constants";

const { getFeatures, providerFactory } = featuresStoreFactory({
  namespace: "plp",
  name: "experiments",
  logWarning: logger[LOG_LEVEL_WARN]
});

export { getFeatures, providerFactory };
