export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_CATEGORY_REQUEST = "SEARCH_CATEGORY_REQUEST";
export const SEARCH_CURATIONS_REQUEST = "SEARCH_CURATIONS_REQUEST";
export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_DETAILS_REQUEST = "CATEGORY_DETAILS_REQUEST";
export const CURATED_CATEGORY_REQUEST = "CURATED_CATEGORY_REQUEST";
export const BRANDS_REQUEST = "BRANDS_REQUEST";
export const FACET_REQUEST = "FACET_REQUEST";
export const SEARCH_EXPERIMENTAL_PARAMS_KEY = "plp-experimental-search-params";
export const SEARCH_GENDERED_SEARCH = "plp-gendered-search";
export const LOYALTY_TIER_QUERY = "customerloyaltytier";
