import { useSelector } from "react-redux";
import {
  getCategoryChildLinks,
  getCategoryIsEarlyAccess,
  getCategoryDetails,
  getIsEarlyAccessCategoryComingSoon
} from "../../state/modules/category";
import { getViewportCategory } from "../../state/modules/layout/selectors";

import CategoryHeader from "./CategoryHeader";
import EAPCategoryHeader from "../EAPCategoryHeader";

const CategoryHeaderContainer = () => {
  const hasHeaderLinks = useSelector(getCategoryChildLinks);
  const isEarlyAccess = useSelector(getCategoryIsEarlyAccess);
  const category = useSelector(getCategoryDetails);
  const viewportCategory = useSelector(getViewportCategory);
  const isComingSoon = useSelector(getIsEarlyAccessCategoryComingSoon);

  return isEarlyAccess ? (
    <EAPCategoryHeader
      hasHeaderLinks={!!hasHeaderLinks.length}
      category={category}
      viewportCategory={viewportCategory}
      isComingSoon={isComingSoon}
    />
  ) : (
    <CategoryHeader hasHeaderLinks={!!hasHeaderLinks.length} />
  );
};

export default CategoryHeaderContainer;
