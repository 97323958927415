import PropTypes from "prop-types";
import { withTranslation } from "../../../translation";
import classnames from "classnames";
import ClearButton from "../../../ToggleButton/ClearButton";
import ScrollVoid from "../../../ScrollVoid";
import SelectAllButton from "../../../ToggleButton/SelectAllButton";
import styles from "./TextMultiSelectDropDown.css";
import TextMultiSelect from "../../../RefinementsList/FacetValues/TextMultiSelect";
import withTextFilter from "../../../../containers/withTextFilter";
import {
  getFacetTotalSelected,
  getFacetSelectedValues
} from "../../../../state/modules/search";
import { NoFilterSearchBoxMatches } from "../../../NoFilterSearchBoxMatches";
import { getFacetValuesToDisplay } from "../../../../helpers/getFacetValuesToDisplay";
import getMaxHeight from "./getMaxHeight";

const TextMultiSelectDropDown = ({
  facet,
  formatTranslation,
  formatNumber,
  selectAll,
  clear,
  changeMultiFacet,
  verticalDisplacement,
  searchBox,
  isFiltered,
  filteredFacetValues
}) => {
  const totalSelected = getFacetTotalSelected(facet);
  const selectedValues = getFacetSelectedValues(facet);
  const maxHeight = getMaxHeight({ verticalDisplacement, searchBox });
  const numberOfOptions = facet.facetValues.length;
  const facetValuesToDisplay = getFacetValuesToDisplay({
    isFiltered,
    filteredFacetValues,
    facet
  });

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.topLine}>
          <div className={styles.listSelections}>
            <p className={styles.totalSelected}>
              {formatTranslation("plp_web_refinements_list_total_selected", {
                formattedCount: formatNumber(totalSelected)
              })}
            </p>
            <p className={styles.selectedList}>{selectedValues}</p>
          </div>
          {!isFiltered && (
            <>
              <ClearButton
                className={styles.clearButton}
                facets={[facet]}
                onClick={() => clear(facet.id)}
              >
                {formatTranslation("plp_web_refinements_list_clear")}
              </ClearButton>
              <SelectAllButton
                className={styles.allButton}
                openFacet={facet}
                onClick={() => selectAll(facet.id)}
              >
                {formatTranslation("plp_web_refinements_list_all")}
              </SelectAllButton>
            </>
          )}
        </div>
        {searchBox}
      </header>
      <fieldset>
        <legend className={styles.srOnly}>{facet.name}</legend>
        <ScrollVoid
          maxHeight={numberOfOptions > 4 ? maxHeight : "none"}
          className={classnames(styles.ul, {
            [styles.listMoreThan4Items]: numberOfOptions > 4,
            [styles[`list${numberOfOptions}Items`]]: !(numberOfOptions > 4)
          })}
        >
          {facetValuesToDisplay.map(facetValue => (
            <li className={styles.li} key={facetValue.id}>
              <TextMultiSelect
                facet={facet}
                changeMultiFacet={changeMultiFacet}
                facetValue={facetValue}
              />
            </li>
          ))}
          <NoFilterSearchBoxMatches
            isFiltered={isFiltered}
            filteredFacetValues={filteredFacetValues}
            formatTranslation={formatTranslation}
          />
        </ScrollVoid>
      </fieldset>
    </div>
  );
};

TextMultiSelectDropDown.propTypes = {
  facet: PropTypes.shape({
    facetType: PropTypes.string.isRequired,
    facetValues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  formatTranslation: PropTypes.func.isRequired,
  formatNumber: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  changeMultiFacet: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  verticalDisplacement: PropTypes.number.isRequired,
  searchBox: PropTypes.element,
  isFiltered: PropTypes.bool,
  filteredFacetValues: PropTypes.array
};

TextMultiSelectDropDown.displayName = "TextMultiSelectDropDown";

export default withTextFilter(withTranslation(TextMultiSelectDropDown));
