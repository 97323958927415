import { loadCategoryDetails } from "../../state/modules/category/actions/loadCategoryDetails";
import shouldGetCategory from "./shouldGetCategory";
import { getLoyaltyTier } from "../../state/modules/config/selectors";

const getCategoryDetailsIfNeeded = async ({
  dispatch,
  store,
  query,
  country,
  language,
  uuid,
  xSiteOrigin,
  isNewPage,
  shouldReloadCategoryDetails,
  refineForCategoryDetails
}) => {
  const state = store.getState();
  const loyaltyTier = getLoyaltyTier(state);

  if (shouldGetCategory(isNewPage)) {
    return dispatch(
      loadCategoryDetails({
        query,
        country,
        language,
        uuid,
        xSiteOrigin,
        loyaltyTier
      })
    );
  }

  if (shouldReloadCategoryDetails) {
    const catDetailsQuery = { ...query, refine: refineForCategoryDetails };

    return dispatch(
      loadCategoryDetails({
        query: catDetailsQuery,
        country,
        language,
        uuid,
        xSiteOrigin,
        loyaltyTier
      })
    );
  }
};

export default getCategoryDetailsIfNeeded;
