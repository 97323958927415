import {
  SEARCH_EXPERIMENTAL_PARAMS_KEY as searchExperimentalParamsFeatureName,
  SEARCH_GENDERED_SEARCH as searchGenderedSearchFeatureName
} from "../../lib/api/constants";
import { useEffect } from "react";
import { getFeatures } from "../../featuresStore";
import { activateFeature } from "@asosteam/asos-web-toggle-point-optimizely";
import sdkInstanceProvider from "../../client/sdkProvider";

const useExperimentalParamsActivation = () => {
  const { decisions, audience } = getFeatures() || {};
  const searchExperimentalParamsDecision =
    decisions?.[searchExperimentalParamsFeatureName];
  const searchGenderedSearchDecision =
    decisions?.[searchGenderedSearchFeatureName];

  useEffect(() => {
    if (searchExperimentalParamsDecision) {
      activateFeature({
        sdkInstanceProvider,
        featureName: searchExperimentalParamsFeatureName,
        audience
      });
    }
  }, [searchExperimentalParamsDecision]);

  useEffect(() => {
    if (searchGenderedSearchDecision) {
      activateFeature({
        sdkInstanceProvider,
        featureName: searchGenderedSearchFeatureName,
        audience
      });
    }
  }, [searchGenderedSearchDecision]);
};

export default useExperimentalParamsActivation;
