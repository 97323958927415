import commonConfig from "../../config/common";
import matchRoutes from "../../routes/matchRoutes";
import { PAGE_NOT_FOUND } from "../../state/modules/page/constants";
import { useEffect } from "react";
import history from "../../client/history";

const {
  globalKeys: { asosKey, siteChromeKey }
} = commonConfig;

const listener = e => {
  const href = e.detail.href;
  const url = new URL(href, "https://www.asos.com/");
  const { pathname, search, hash } = url;

  if (matchRoutes({ pathname }).key !== PAGE_NOT_FOUND) {
    e.preventDefault();
    history.push(`${pathname}${search}${hash}`);
  }
};

export const useNavigationCapture = () => {
  const { navigation } = window[asosKey][siteChromeKey];

  useEffect(() => {
    navigation.addEventListener(listener);

    return () => {
      navigation.removeEventListener(listener);
    };
  }, []);
};
