import PropTypes from "prop-types";
import classnames from "classnames";
import media from "../customMedia.json";
import getFallbackImage from "./getFallbackImage";
import { useContext } from "react";
import { TILE_SIZES } from "../../helpers/constants";
import TileContext from "../TileContext";

const { TILE_SIZE_TWO_BY_ONE, TILE_SIZE_TWO_BY_TWO } = TILE_SIZES;

const srcSetList = [
  [240, 120],
  [240, 238],
  [320, 317],
  [480, 476],
  [640, 634],
  [750, 714],
  [960, 952]
];

const getSrcSet = src =>
  srcSetList
    .map(
      ([presetSize, ourSize]) =>
        `//${src}?$n_${presetSize}w$&wid=${ourSize}&fit=constrain ${ourSize}w`
    )
    .join(",");

const [mediumMinWidth] = media.customMedia[`--medium-viewport`].match(
  /(\(min-width: [^)]+\))/
);

const DEFAULT_IMAGE_SIZES = `${mediumMinWidth} 317px, 238px`;

const TILE_SIZE_TO_IMAGE_SIZES = {
  [TILE_SIZE_TWO_BY_ONE]: `${mediumMinWidth} 323px, 256px`,
  [TILE_SIZE_TWO_BY_TWO]: `${mediumMinWidth} 705px, 558px`
};

const getImageSizes = tileSize =>
  TILE_SIZE_TO_IMAGE_SIZES[tileSize] || DEFAULT_IMAGE_SIZES;

const ProductImage = ({
  alt,
  description,
  className,
  image,
  onMouseOver,
  onMouseLeave,
  onError,
  onLoad,
  onTransitionEnd
}) => {
  const { tileSize } = useContext(TileContext);

  return (
    <img
      alt={alt || description}
      className={classnames(className)}
      sizes={getImageSizes(tileSize)}
      src={getFallbackImage(image)}
      srcSet={getSrcSet(image)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onError={onError}
      onLoad={onLoad}
      onTransitionEnd={onTransitionEnd}
    />
  );
};

ProductImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  onTransitionEnd: PropTypes.func,
  alt: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default ProductImage;
