export const SET_FLOOR = "SET_FLOOR";
export const SET_LOCALE = "SET_LOCALE";
export const SET_PLATFORM = "SET_PLATFORM";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_STORE_URL = "SET_STORE_URL";
export const SET_SIZE_SCHEMA = "SET_SIZE_SCHEMA";
export const SET_GEOLOCATION = "SET_GEOLOCATION";
export const SET_LOYALTY_TIER = "SET_LOYALTY_TIER";

// Platforms
export const MOBILE = "mobile";
export const TABLET = "tablet";
export const DESKTOP = "desktop";

// Gender
export const FLOOR_WOMEN = "women";
export const FLOOR_MEN = "men";
export const FLOOR_DEFAULT = "default";
