import PropTypes from "prop-types";
import styles from "./index.css";
import classNames from "classnames";
import { DateTime } from "luxon";
import { withTranslation } from "../../translation";
import getCopyKeys from "./getCopyKeys";
import Ribbon from "./Ribbon";

const CategoryHeaderCopy = ({
  header,
  isComingSoon,
  endDate,
  formatTranslation
}) => {
  const copyKeys = getCopyKeys(isComingSoon);

  return (
    <div className={styles.copy}>
      <div className={styles.constrained}>
        <div className={styles.content}>
          <div className={styles.leftSpacer}></div>
          <div>
            <p>
              <span className={classNames("barnsley3", styles.header)}>
                {header}
              </span>
            </p>
            <p className={styles.mobileMidSpacer}></p>
            <p className={styles.midSpacer}></p>
            <p>
              <span className={classNames("london2", styles.eapLabel)}>
                {formatTranslation(copyKeys.intro)}
              </span>
            </p>
            <p>
              <span className={classNames("london2", styles.eapLabel)}>
                {DateTime.fromISO(endDate).toFormat("ccc dd MMM")}
              </span>
            </p>
            <p>
              <span className={classNames("leavesden2", styles.description)}>
                {formatTranslation(copyKeys.description)}
              </span>
            </p>
          </div>
        </div>
      </div>
      <Ribbon isComingSoon={isComingSoon} />
    </div>
  );
};

CategoryHeaderCopy.propTypes = {
  header: PropTypes.string.isRequired,
  isComingSoon: PropTypes.bool,
  endDate: PropTypes.object.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(CategoryHeaderCopy);
