import PropTypes from "prop-types";
import { useRef, useContext } from "react";
import styles from "./ProductTile.css";
import getDealDetails from "./getDealDetails";
import ProductDescription from "./ProductDescription";
import ProductPrice from "./ProductPrice";
import SaveForLater from "./SaveForLater";
import Banners from "./Banners";
import ProductLink from "./ProductLink";
import classnames from "classnames";
import { withTranslation } from "../translation";
import { MIX_MATCH_GROUP } from "../../state/modules/search/constants";
import getAriaLabelledBy from "./getAriaLabelledBy";
import useOnViewBeacon from "./UseOnViewBeacon";
import useOnLoadBeacon from "./useOnLoadBeacon";
import TileContext from "../TileContext";
import { TILE_SIZES } from "../../helpers/constants";
import ProductMediaContainer from "./ProductMediaContainer";

const { TILE_SIZE_TWO_BY_ONE, TILE_SIZE_TWO_BY_TWO } = TILE_SIZES;

export const ProductTile = ({
  id,
  tileId,
  url,
  price,
  isSale,
  isOutlet,
  productType,
  description,
  colourWayId,
  reducedPrice,
  formatCurrency,
  formatTranslation,
  hasMultiplePrices,
  ProductHeroComponent,
  analyticsProductClick,
  className,
  carouselCatId,
  euOmnibusEnabled,
  dealBannerEnabled,
  advertisement: {
    id: advertId,
    onClickBeacon,
    onViewBeacon,
    onLoadBeacon,
    onWishlistBeacon
  },
  sendPlacementOnViewBeacon,
  uponTileBadges = () => null,
  underneathTileBadges = () => null,
  ...rest
}) => {
  const getProductTileId = id => `product-${id}`;
  const productTileId = getProductTileId(id);
  const labels = getAriaLabelledBy({ productId: productTileId });
  const { tileSize } = useContext(TileContext);

  const tileElement = useRef();

  useOnLoadBeacon({ onLoadBeacon });

  useOnViewBeacon({
    targetElement: tileElement.current,
    onViewBeacon,
    sendPlacementOnViewBeacon
  });

  const wrappedAnalyticsProductClick = obj =>
    analyticsProductClick({ ...obj, product: { ...rest } });

  const {
    formattedPrice,
    formattedReducedPrice,
    deal,
    dealAria
  } = getDealDetails({
    price,
    reducedPrice,
    formatCurrency,
    formatTranslation,
    euOmnibusEnabled,
    dealBannerEnabled
  });

  return (
    <article
      role="group"
      aria-label="product"
      ref={tileElement}
      id={getProductTileId(tileId)}
      className={classnames(
        styles.productTile,
        {
          [styles.twoByOne]: tileSize === TILE_SIZE_TWO_BY_ONE,
          [styles.twoByTwo]: tileSize === TILE_SIZE_TWO_BY_TWO
        },
        className
      )}
    >
      <ProductLink
        className={styles.productLink}
        href={url}
        productTileId={productTileId}
        carouselCatId={carouselCatId}
        productId={id}
        onClickBeacon={onClickBeacon}
        advertId={advertId}
        analyticsProductClick={wrappedAnalyticsProductClick}
        {...rest}
      >
        <ProductMediaContainer className={styles.mediaContainer}>
          <ProductHeroComponent description={description} {...rest} />
          <Banners {...{ labels, deal, dealAria, ...rest }} />
          {uponTileBadges({ labels, ...rest })}
        </ProductMediaContainer>
        <div className={styles.productInfo}>
          <ProductDescription text={description} labelledById={labels.title} />
          <ProductPrice
            price={formattedPrice}
            isSale={isSale}
            isOutlet={isOutlet}
            reducedPrice={formattedReducedPrice}
            hasMultiplePrices={hasMultiplePrices}
            labelledById={labels.price}
            euOmnibusEnabled={euOmnibusEnabled}
            {...rest}
          />
          {underneathTileBadges({ labels, ...rest })}
        </div>
      </ProductLink>
      {productType !== MIX_MATCH_GROUP && (
        <SaveForLater
          {...{
            productId: id,
            price,
            reducedPrice,
            colourWayId,
            carouselCatId,
            description,
            onWishlistBeacon,
            className: styles.bottomRightButton,
            ...rest
          }}
        />
      )}
    </article>
  );
};

ProductTile.propTypes = {
  isSale: PropTypes.bool,
  isOutlet: PropTypes.bool,
  reducedPrice: PropTypes.number,
  id: PropTypes.number.isRequired,
  tileId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  hasMultiplePrices: PropTypes.bool,
  price: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  colourWayId: PropTypes.number.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  productType: PropTypes.string.isRequired,
  ProductHeroComponent: PropTypes.elementType.isRequired,
  className: PropTypes.string,
  carouselCatId: PropTypes.number,
  euOmnibusEnabled: PropTypes.bool.isRequired,
  dealBannerEnabled: PropTypes.bool.isRequired,
  advertisement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    onClickBeacon: PropTypes.string.isRequired,
    onViewBeacon: PropTypes.string.isRequired,
    onLoadBeacon: PropTypes.string.isRequired,
    onWishlistBeacon: PropTypes.string.isRequired
  }),
  sendPlacementOnViewBeacon: PropTypes.func,
  analyticsProductClick: PropTypes.func,
  uponTileBadges: PropTypes.func,
  underneathTileBadges: PropTypes.func
};

export default withTranslation(ProductTile);
