const getPositionInItemsArrayFromPositionInProductTilesArray = productsTiles => ({
  positionInProductTilesArray,
  items,
  virtualProducts
}) => {
  const expandedItems = items.concat(virtualProducts);
  const expandedProductsTiles = productsTiles.concat(virtualProducts);

  const preceedingProductTile =
    expandedProductsTiles[positionInProductTilesArray - 1];

  const preceedingProductTileIndexInItemsArray = expandedItems.indexOf(
    preceedingProductTile
  );

  return preceedingProductTileIndexInItemsArray + 1;
};

export default getPositionInItemsArrayFromPositionInProductTilesArray;
