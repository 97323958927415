import * as types from "../CategoryHeaderMedia/constants";

import { SMALL_VIEWPORT } from "../../state/modules/layout/constants";

const getMediaType = ({ viewportCategory, mediaUrls }) =>
  (viewportCategory === SMALL_VIEWPORT && mediaUrls.mobile.videoUrl) ||
  (viewportCategory !== SMALL_VIEWPORT && mediaUrls.desktop.videoUrl)
    ? types.VIDEO_MEDIA_TYPE
    : types.IMAGE_MEDIA_TYPE;

export default getMediaType;
