import { createAction } from "redux-actions";
import {
  search,
  searchCategory,
  searchCuratedCategory
} from "../../../lib/api/search";
import {
  REQUEST_SEARCH,
  RECEIVE_SEARCH,
  UPDATE_MULTI_FACET,
  CLEAR_SELECTIONS,
  MORE_PRODUCTS_LOADED,
  PREVIOUS_PRODUCTS_LOADED,
  LOADING_MORE_PRODUCTS,
  LOADING_PREVIOUS_PRODUCTS,
  UPDATE_SORT_FACET,
  SET_NEW_SEARCH,
  CLEAR_NEW_SEARCH,
  RESTORE_OLD_SELECTED_FACETS,
  UPDATE_PERSONALISATION_PARAMS,
  SET_ADDITIONAL_QUERY_PARAMS,
  CLEAR_ON_VIEW_BEACON,
  REFRESH_ADVERTISEMENT_BEACONS,
  CLEAR_ON_LOAD_BEACON
} from "./constants";

import logger from "../../../lib/logger";
import {
  getPageSize,
  getPaginationData,
  getAdditionalQueryParams,
  getAdvertisementsAnalytics
} from "./selectors";
import { isLargeMediumViewPort } from "./viewportHelper";
import { getLocation } from "../router/selectors";
import {
  getSearchApiDefaults,
  getNorthAmericanRegion
} from "../config/selectors";
import config from "../../../config/common";
import applyFacets from "./facets/apply";
import { updateLocationQuery } from "../router/actions";
import {
  analyticsLoadMore,
  analyticsLoadPrevious,
  analyticsRefinementsToggled,
  analyticsAddPendingRefinementsToggled,
  analyticsAddPendingSortRefinement,
  applyAndRemovePendingEvents
} from "../analytics/actions";
import { clearError, setError } from "../error";
import { ERROR_ORIGIN } from "../../../components/ErrorAlert/constants";
import { CATEGORY_PAGE } from "../page/constants";
import { filterInvalidProducts } from "./product";
import clientSideRedirect from "../../../client/clientSideRedirect";
import getPageNumberFromQuery from "./getPageNumberFromQuery";

export { default as clear } from "./facets/clear";
export { default as clearAll } from "./facets/clearAll";
export { default as selectAll } from "./facets/selectAll";
export { default as applyFacets } from "./facets/apply";
export { default as done } from "./facets/done";
export { undoSearch } from "./undo";
export {
  updateRangeFacet,
  setNextPriceRangeToCurrent,
  changeRangeFacet,
  applyRangeFacet,
  clearRangeFacet
} from "./range/actions";
import { getAdvertisingQueryParams } from "./getAdvertisingQueryParams";
import { getCategoryTreatments } from "../category/selectors";
import { getPageName } from "../page/selectors";
import { clearCuratedCategoryTreatments } from "../category";
const pageSize = config.api.product.search.pageSize;
export const requestProducts = createAction(REQUEST_SEARCH);
export const receiveProducts = createAction(RECEIVE_SEARCH, response => ({
  ...response,
  products: filterInvalidProducts(response.products)
}));
export const moreProductsLoaded = createAction(
  MORE_PRODUCTS_LOADED,
  response => ({
    ...response,
    products: filterInvalidProducts(response.products)
  })
);
export const previousProductsLoaded = createAction(
  PREVIOUS_PRODUCTS_LOADED,
  response => ({
    ...response,
    products: filterInvalidProducts(response.products)
  })
);
export const loadingPreviousProducts = createAction(LOADING_PREVIOUS_PRODUCTS);
export const loadingMoreProducts = createAction(LOADING_MORE_PRODUCTS);
export const restoreOldSelectedFacets = createAction(
  RESTORE_OLD_SELECTED_FACETS
);

export const updatePersonalisationParams = personalisationParams => ({
  type: UPDATE_PERSONALISATION_PARAMS,
  payload: personalisationParams
});

export const setAdditionalQueryParams = additionalQueryParams => ({
  type: SET_ADDITIONAL_QUERY_PARAMS,
  payload: additionalQueryParams
});

export const getSearchActionCreator = ({
  state,
  refine,
  sort,
  dispatch,
  useCuratedSearchFallback
}) => {
  const pageName = getPageName(state);
  const isCategory = pageName === CATEGORY_PAGE;

  if (!isCategory) {
    return search;
  }

  const hasTreatments = getCategoryTreatments(state);

  const curatedSearchWrapper = useCuratedSearchFallback
    ? (...args) =>
        searchCuratedCategory.apply(this, args).catch(error => {
          error.message = `Curated Search failed for cid ${args[0]?.cid}, falling back to regular search`;

          logger.error(error);

          dispatch(clearCuratedCategoryTreatments());

          return searchCategory.apply(this, args);
        })
    : searchCuratedCategory;

  return hasTreatments && !refine && !sort
    ? curatedSearchWrapper
    : searchCategory;
};

export const getSearchAction = (state, payload) => {
  const newPayload = {
    ...payload
  };

  const northAmericanRegion = getNorthAmericanRegion(state);

  if (northAmericanRegion) {
    newPayload.region = northAmericanRegion;
  }

  return getSearchActionCreator({
    state,
    refine: payload.refine,
    sort: payload.sort
  })(newPayload);
};

const getFetchAction = ({ page: { name: pageName } }) =>
  pageName === CATEGORY_PAGE ? fetchCategory() : fetchSearch();

const reapplyFacets = () => dispatch => {
  dispatch(restoreOldSelectedFacets());
  dispatch(applyFacets());
};

const clearOnViewBeacon = createAction(CLEAR_ON_VIEW_BEACON);
export const sendPlacementOnViewBeacon = () => (dispatch, getState) => {
  const state = getState();
  const advertisementsAnalytics = getAdvertisementsAnalytics(state);
  const onViewBeacon = advertisementsAnalytics?.placementBeacons?.onViewBeacon;

  if (onViewBeacon) {
    navigator.sendBeacon(onViewBeacon);
    dispatch(clearOnViewBeacon());
  }
};

const clearOnLoadBeacon = createAction(CLEAR_ON_LOAD_BEACON);
export const sendPlacementOnLoadBeacon = () => (dispatch, getState) => {
  const state = getState();
  const advertisementsAnalytics = getAdvertisementsAnalytics(state);
  const onLoadBeacon = advertisementsAnalytics?.placementBeacons?.onLoadBeacon;

  if (onLoadBeacon) {
    navigator.sendBeacon(onLoadBeacon);
    dispatch(clearOnLoadBeacon());
  }
};

export function retrySearch({ actionType, forceReapplyFacets } = {}) {
  return (dispatch, getState) => {
    switch (actionType) {
      case PREVIOUS_PRODUCTS_LOADED:
        dispatch(loadPrevious());
        break;
      case MORE_PRODUCTS_LOADED:
        dispatch(loadMore());
        break;
      default:
        forceReapplyFacets
          ? dispatch(reapplyFacets())
          : dispatch(getFetchAction(getState()));
        break;
    }
  };
}
export function loadPrevious() {
  return (dispatch, getState) => {
    const state = getState();
    const location = getLocation(state);
    const pagination = getPaginationData(state);
    const page = pagination.page.previous;
    if (pagination.offset.previous !== null) {
      dispatch(loadingPreviousProducts());

      const queryObject = getQueryObject({ state }, pagination.offset.previous);

      return getSearchAction(state, queryObject)
        .then(response => {
          dispatch(clearError());
          dispatch(previousProductsLoaded({ ...response, page }));
          dispatch(analyticsLoadPrevious({ response, page }));
        })
        .catch(error => {
          error.message = `${error.message} loadPrevious for ${location.query.q} failed`;
          logger.error(error);
          dispatch(
            setError({
              message: error.message,
              actionType: PREVIOUS_PRODUCTS_LOADED,
              errorOrigin: ERROR_ORIGIN.search
            })
          );
          dispatch(previousProductsLoaded(error));
        });
    }
  };
}
export function loadMore() {
  return (dispatch, getState) => {
    const state = getState();
    const { query } = getLocation(state);
    const pagination = getPaginationData(state);
    const page = pagination.page.next;

    if (pagination.offset.next) {
      const updatedQuery = {
        ...query,
        page
      };
      dispatch(loadingMoreProducts());
      dispatch(updateLocationQuery({ query: updatedQuery }));

      const queryObject = getQueryObject({ state }, pagination.offset.next);

      return getSearchAction(state, queryObject)
        .then(response => {
          dispatch(clearError());
          dispatch(moreProductsLoaded({ ...response, page }));
          dispatch(analyticsLoadMore({ response, page }));
        })
        .catch(error => {
          error.message = `${error.message} loadMore for ${query.q} failed`;
          logger.error(error);
          dispatch(
            setError({
              message: error.message,
              actionType: MORE_PRODUCTS_LOADED,
              errorOrigin: ERROR_ORIGIN.search
            })
          );
          dispatch(moreProductsLoaded(error));
        });
    }
  };
}

const getQueryObject = ({ state, isNewPage }, paginationOffset = null) => {
  const { query } = getLocation(state);
  const limit = getPageSize(state);

  const queryObject = {
    ...query,
    ...getSearchApiDefaults(state, isNewPage),
    ...getAdvertisingQueryParams(state),
    offset:
      paginationOffset !== null
        ? paginationOffset
        : pageSize * (getPageNumberFromQuery(query) - 1),
    limit
  };

  const northAmericanRegion = getNorthAmericanRegion(state);

  if (northAmericanRegion) {
    queryObject.region = northAmericanRegion;
  }

  return queryObject;
};

function fetchProduct(
  isCategory,
  uuid,
  xSiteOriginFromServerRequest,
  isNewPage
) {
  return (dispatch, getState) => {
    const state = getState();
    const query = getQueryObject({ state, isNewPage });

    dispatch(requestProducts({ query, isNewPage }));
    const searchFunction = getSearchActionCreator({
      state,
      refine: query.refine,
      sort: query.sort,
      dispatch,
      useCuratedSearchFallback: true
    });

    const commonThings = async response => {
      if (isNewPage && clientSideRedirect({ response, state })) {
        return null;
      }

      dispatch(clearError());

      await dispatch(
        receiveProducts({
          ...response,
          query,
          isNewPage
        })
      );

      dispatch(applyAndRemovePendingEvents());
    };

    return searchFunction(query, uuid, xSiteOriginFromServerRequest)
      .then(response => {
        const { products } = response;

        const additionalQueryParams = getAdditionalQueryParams(state);
        const floorExists = !!additionalQueryParams?.refine?.includes("floor");

        if (products?.length === 0 && floorExists) {
          return searchFunction(
            query,
            uuid,
            xSiteOriginFromServerRequest,
            true
          ).then(response => {
            commonThings(response);
          });
        } else {
          commonThings(response);
        }
      })
      .catch(error => {
        if (isNewPage) {
          dispatch(receiveProducts(error));
          throw error;
        }
        error.message = `${
          isCategory
            ? `${error.message} fetchCategory failed`
            : `${error.message} fetchSearch for ${query.q} failed`
        }`;
        logger.error(error);
        dispatch(
          setError({
            message: error.message,
            actionType: RECEIVE_SEARCH,
            errorOrigin: ERROR_ORIGIN.search
          })
        );

        return dispatch(receiveProducts(error));
      });
  };
}
export const fetchSearch = fetchProduct.bind(null, false);
export const fetchCategory = fetchProduct.bind(null, true);
export const updateMultiFacet = createAction(UPDATE_MULTI_FACET);
export function changeMultiFacet(toggledFacets) {
  return dispatch => {
    dispatch(updateMultiFacet(toggledFacets));
  };
}
export function changeMultiFacetAndApplyIfLargeMedium(toggledFacets) {
  return (dispatch, getState) => {
    dispatch(
      updateMultiFacet(
        toggledFacets.map(({ facet, facetValue, isSelected }) => ({
          facetId: facet.id,
          valueId: facetValue.id,
          isSelected
        }))
      )
    );
    if (isLargeMediumViewPort(getState())) {
      dispatch(applyFacets());
      dispatch(analyticsAddPendingRefinementsToggled(toggledFacets));
    } else {
      dispatch(analyticsRefinementsToggled(toggledFacets));
    }
  };
}
const updateSortFacet = createAction(UPDATE_SORT_FACET);
export const changeSort = ({ type, name }) => dispatch => {
  dispatch(updateSortFacet(type));
  dispatch(analyticsAddPendingSortRefinement({ type, name }));
  dispatch(applyFacets());
};
export const clearSelections = createAction(CLEAR_SELECTIONS);
export const setNewSearch = createAction(SET_NEW_SEARCH);
export const clearNewSearch = createAction(CLEAR_NEW_SEARCH);
export const refreshAdvertisementBeacons = createAction(
  REFRESH_ADVERTISEMENT_BEACONS
);
