import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CategoryHeader from "./../../../CategoryHeader";
import CategoryHeaderV2 from "./CategoryHeader";
import EAPCategoryHeader from "../../../../EAPCategoryHeader";
import {
  getCategoryChildLinks,
  getCategoryId,
  getCategoryDetails,
  getCategoryIsEarlyAccess,
  getIsEarlyAccessCategoryComingSoon
} from "../../../../../state/modules/category";
import { getViewportCategory } from "../../../../../state/modules/layout/selectors";
import { PLP_FEATURED_HEADER } from "../../../../constants";
import * as types from "../../../../CategoryHeaderMedia/constants";
import { getFeatures } from "../../../../../featuresStore";

const CategoryHeaderContainer = ({ scrollFunction }) => {
  const headerLinks = useSelector(getCategoryChildLinks);
  const hasHeaderLinks = !!headerLinks.length;
  const categoryId = useSelector(getCategoryId);
  const isEarlyAccess = useSelector(getCategoryIsEarlyAccess);
  const isComingSoon = useSelector(getIsEarlyAccessCategoryComingSoon);

  const featuredHeaderDecisionParams = getFeatures()?.decisions?.[
    PLP_FEATURED_HEADER
  ];
  const mediaType =
    featuredHeaderDecisionParams &&
    featuredHeaderDecisionParams.params[categoryId];
  const category = useSelector(getCategoryDetails);
  const viewportCategory = useSelector(getViewportCategory);

  if (isEarlyAccess) {
    return (
      <EAPCategoryHeader
        hasHeaderLinks={!!hasHeaderLinks.length}
        category={category}
        viewportCategory={viewportCategory}
        isComingSoon={isComingSoon}
      />
    );
  }

  if (!Object.values(types).includes(mediaType)) {
    return <CategoryHeader hasHeaderLinks={hasHeaderLinks} />;
  }

  return (
    <CategoryHeaderV2
      hasHeaderLinks={hasHeaderLinks}
      category={category}
      mediaType={mediaType}
      viewportCategory={viewportCategory}
      scrollFunction={scrollFunction}
    />
  );
};

CategoryHeaderContainer.propTypes = {
  scrollFunction: PropTypes.func.isRequired
};

export default CategoryHeaderContainer;
