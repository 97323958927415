import { useSelector } from "react-redux";
import { shouldRenderPagination } from "../../state/selectors";
import {
  getPaginationInitial,
  getPaginationMax
} from "../../state/modules/search";
import { getCanonicalUrlFromCategoryDetailsByLocale } from "../../state/modules/category/selectors";
import { withTranslation } from "../../components/translation";
import Pagination from "../../components/Pagination";

const ConnectedPagination = props => {
  const shouldRender = useSelector(shouldRenderPagination);
  if (!shouldRender) {
    return null;
  }

  return (
    <Pagination
      {...props}
      currentPage={useSelector(getPaginationInitial)}
      maxPage={useSelector(getPaginationMax)}
      url={useSelector(getCanonicalUrlFromCategoryDetailsByLocale)}
    />
  );
};

export default withTranslation(ConnectedPagination);
