import ProductBadge from "./ProductBadge";
import SellingFastBadge from "./SellingFastBadge";
import RestockingSoonBadge from "./RestockingSoonBadge";
import EarlyAccessBadge from "./EarlyAccessBadge";
import classnames from "classnames";

const badgesFactory = ({
  restockingSoonClasses,
  productClasses,
  sellingFastClasses,
  earlyAccessClasses
}) => {
  const Badges = props => (
    <>
      {earlyAccessClasses && (
        <EarlyAccessBadge
          key={"earlyAccess"}
          {...props}
          className={classnames(earlyAccessClasses)}
        />
      )}
      {restockingSoonClasses && (
        <RestockingSoonBadge
          key={"restockingSoon"}
          {...props}
          className={classnames(restockingSoonClasses)}
        />
      )}
      {productClasses && (
        <ProductBadge
          key={"product"}
          {...props}
          className={classnames(productClasses)}
        />
      )}
      {sellingFastClasses && (
        <SellingFastBadge
          key={"sellingFast"}
          {...props}
          className={classnames(sellingFastClasses)}
        />
      )}
    </>
  );

  return Badges;
};

export default badgesFactory;
