export const TILE_SIZE_ONE_BY_ONE = "tileSizeOneByOne";
export const TILE_SIZE_TWO_BY_ONE = "tileSizeTwoByOne";
export const TILE_SIZE_TWO_BY_TWO = "tileSizeTwoByTwo";

export const TILE_SIZES = {
  TILE_SIZE_ONE_BY_ONE,
  TILE_SIZE_TWO_BY_ONE,
  TILE_SIZE_TWO_BY_TWO
};

export const ACCESS_LEVEL_VIEW = "view";
export const ACCESS_LEVEL_NONE = "none";
