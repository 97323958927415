import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";
import {
  SMALL_VIEWPORT,
  viewportPropType
} from "@template/state/modules/layout";
import IconButton from "../IconButton";
import Widget from "./Widget";
import styles from "./Widgets.css";
import widgetStyles from "./Widget/index.css";
import Dropdown from "../Dropdown";
import Greeter from "../Greeter";
import MyAccountLinks from "../MyAccountLinks";
import MiniBag from "../MiniBag";
import MiniBagHeader from "../MiniBag/Header";
import MiniBagFooter from "../MiniBag/Footer";
import { withTranslation } from "@template/components/translation";
import { getMyAccountBaseUrl } from "@template/helpers/externalLinks";
import { withParams } from "@src/helpers/queryString";
import {
  MY_ACCOUNT,
  MINIBAG,
  SEARCH,
  SAVED_ITEMS
} from "@template/state/modules/dropdown";
import pageSupportsMiniBagWithDropdown from "@template/helpers/pageSupportsMiniBagWithDropdown";
import {
  MY_ACCOUNT_FILLED,
  MY_ACCOUNT_UNFILLED,
  BAG_FILLED,
  BAG_UNFILLED,
  SAVED_ITEMS_FILLED,
  SAVED_ITEMS_UNFILLED,
  MY_ACCOUNT_CHRISTMAS_FILLED,
  MY_ACCOUNT_CHRISTMAS_UNFILLED,
  MY_ACCOUNT_EASTER_FILLED,
  MY_ACCOUNT_EASTER_UNFILLED,
  MY_ACCOUNT_HALLOWEEN_FILLED,
  MY_ACCOUNT_HALLOWEEN_UNFILLED,
  MY_ACCOUNT_STPATRICKS_FILLED,
  MY_ACCOUNT_STPATRICKS_UNFILLED,
  SAVED_ITEMS_PRIDE_FILLED,
  SAVED_ITEMS_VALENTINES_FILLED,
  SAVED_ITEMS_VALENTINES_UNFILLED
} from "./constants";
import { APP_ROUTES } from "@template/constants";
import {
  CHRISTMAS,
  EASTER,
  HALLOWEEN,
  STPATRICKS,
  VALENTINES,
  PRIDE
} from "@template/helpers/eventConstants";

const trackingQuery = "?nlid=nav header";

const Widgets = ({
  getSavedItemsFromSDK,
  noOfBagItems,
  isUpdated,
  openSearch,
  addLastFocusElement,
  formatTranslation,
  countryCode,
  isLoggedIn,
  keyStoreDataversion,
  lang,
  myAccountOpen,
  storeCode,
  viewport,
  defaultStoreUrl,
  hasSavedItems,
  miniBagOpen,
  currentEvent
}) => {
  const numberOfBagItemsRef = useRef(noOfBagItems);
  const [isBagIconAnimating, setIsBagIconAnimating] = useState(false);
  const [
    pageSupportsMiniBagWithDropdownState,
    setPageSupportsMiniBagWithDropdownState
  ] = useState(false);

  const handleSearch = () => {
    openSearch({ isModal: true });
    addLastFocusElement(`[data-testid='${SEARCH}Icon']`);
  };

  const toggleAnimation = () => {
    setIsBagIconAnimating(
      previousValueOfSetIsBagIconAnimating =>
        !previousValueOfSetIsBagIconAnimating
    );
  };

  const handleAccountIconState = (activeEvent, isLoggedIn) => {
    if (!activeEvent) {
      return isLoggedIn ? MY_ACCOUNT_FILLED : MY_ACCOUNT_UNFILLED;
    }
    return isLoggedIn ? activeEvent.loggedIn : activeEvent.loggedOut;
  };

  useEffect(() => {
    getSavedItemsFromSDK();

    setPageSupportsMiniBagWithDropdownState(
      pageSupportsMiniBagWithDropdown(window.location.pathname)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUpdated && noOfBagItems > numberOfBagItemsRef.current) {
      toggleAnimation();
    }
    numberOfBagItemsRef.current = noOfBagItems;
  }, [noOfBagItems, isUpdated]);

  const searchWidget = () => (
    <Widget viewable={[SMALL_VIEWPORT]}>
      <IconButton
        type="button"
        icon={styles.widgetSearch}
        onClick={handleSearch}
        data-testid={`${SEARCH}Icon`}
        className={styles.widgetButton}
        aria-label={formatTranslation("icon_search")}
        id={`${SEARCH}Icon`}
      />
    </Widget>
  );

  const myAccountWidget = () => {
    const getMyAccountIcon = () => {
      const eventIconsAccount = [
        {
          event: CHRISTMAS,
          loggedIn: MY_ACCOUNT_CHRISTMAS_FILLED,
          loggedOut: MY_ACCOUNT_CHRISTMAS_UNFILLED
        },
        {
          event: STPATRICKS,
          loggedIn: MY_ACCOUNT_STPATRICKS_FILLED,
          loggedOut: MY_ACCOUNT_STPATRICKS_UNFILLED
        },
        {
          event: EASTER,
          loggedIn: MY_ACCOUNT_EASTER_FILLED,
          loggedOut: MY_ACCOUNT_EASTER_UNFILLED
        },
        {
          event: HALLOWEEN,
          loggedIn: MY_ACCOUNT_HALLOWEEN_FILLED,
          loggedOut: MY_ACCOUNT_HALLOWEEN_UNFILLED
        }
      ];
      const activeEvent = eventIconsAccount.find(
        eventIcon => eventIcon.event === currentEvent
      );

      return handleAccountIconState(activeEvent, isLoggedIn);
    };

    const myAccountIcon = getMyAccountIcon();

    if (viewport !== SMALL_VIEWPORT) {
      return (
        <Widget className={widgetStyles.myAccountWidget}>
          <Dropdown
            name={MY_ACCOUNT}
            header={<Greeter />}
            body={<MyAccountLinks />}
            footer={null}
          >
            <IconButton
              type="button"
              icon={myAccountIcon.icon}
              icontype={myAccountIcon.type}
              data-testid={`${MY_ACCOUNT}Icon`}
              className={styles.widgetButton}
              aria-expanded={myAccountOpen}
              aria-controls="myaccount-dropdown"
              aria-label={formatTranslation("icon_myaccount")}
            />
          </Dropdown>
        </Widget>
      );
    } else {
      const params = {
        lang,
        store: storeCode,
        country: countryCode,
        keyStoreDataversion,
        nlid: "nav header"
      };

      return (
        <Widget className={widgetStyles.myAccountWidget}>
          <IconButton
            type="a"
            href={withParams(getMyAccountBaseUrl(), params)}
            icon={myAccountIcon.icon}
            icontype={myAccountIcon.type}
            data-testid={`${MY_ACCOUNT}Icon`}
            className={styles.widgetButton}
            aria-label={formatTranslation("icon_myaccount")}
          />
        </Widget>
      );
    }
  };

  const savedItemsWidget = () => {
    const getSavedItemsIcon = () => {
      const eventIcons = [
        {
          event: VALENTINES,
          withSavedItems: SAVED_ITEMS_VALENTINES_FILLED,
          withoutSavedItems: SAVED_ITEMS_VALENTINES_UNFILLED
        },
        {
          event: PRIDE,
          withSavedItems: SAVED_ITEMS_PRIDE_FILLED,
          withoutSavedItems: SAVED_ITEMS_PRIDE_FILLED
        }
      ];

      const activeEvent = eventIcons.find(
        eventIcon => eventIcon.event === currentEvent
      );

      if (!activeEvent) {
        const defaultIcon = hasSavedItems
          ? SAVED_ITEMS_FILLED
          : SAVED_ITEMS_UNFILLED;

        return defaultIcon;
      }

      const eventIcon = hasSavedItems
        ? activeEvent.withSavedItems
        : activeEvent.withoutSavedItems;

      return eventIcon;
    };

    const savedItemsIcon = getSavedItemsIcon();

    const savedItemsUrl = defaultStoreUrl.concat(
      APP_ROUTES.SAVED_ITEMS_LINK,
      trackingQuery
    );

    return (
      <Widget>
        <IconButton
          type="a"
          href={savedItemsUrl}
          icon={savedItemsIcon.icon}
          icontype={savedItemsIcon.type}
          data-testid={`${SAVED_ITEMS}Icon`}
          className={styles.widgetButton}
          aria-label={formatTranslation("icon_saveditems")}
        />
      </Widget>
    );
  };

  const bagWidget = () => {
    const bagIcon = noOfBagItems ? BAG_FILLED : BAG_UNFILLED;
    const bagUrl = defaultStoreUrl.concat(APP_ROUTES.BAG_LINK, trackingQuery);
    const bagAriaLabel = `${formatTranslation("icon_bag")} ${formatTranslation(
      "minibag_itemcount",
      {
        smart_count: noOfBagItems
      }
    )}`;

    const shouldRenderDropdown = () =>
      viewport !== SMALL_VIEWPORT &&
      noOfBagItems &&
      pageSupportsMiniBagWithDropdownState;

    const getBagIcon = (hasDropdown = false) => (
      <IconButton
        type={hasDropdown ? "button" : "a"}
        href={hasDropdown ? null : bagUrl}
        icon={bagIcon.icon}
        icontype={bagIcon.type}
        data-testid={`${MINIBAG}Icon`}
        className={styles.widgetButton}
        aria-label={bagAriaLabel}
        aria-controls={hasDropdown ? "minibag-dropdown" : null}
        aria-expanded={hasDropdown ? (miniBagOpen ? true : false) : null}
      >
        {noOfBagItems ? (
          <span className={styles.noOfBagItems}>{noOfBagItems}</span>
        ) : null}
      </IconButton>
    );

    return (
      <Widget>
        {shouldRenderDropdown() ? (
          <Dropdown
            name={MINIBAG}
            header={<MiniBagHeader />}
            body={<MiniBag />}
            footer={<MiniBagFooter />}
            preventCloseOnMouseLeave
          >
            {getBagIcon(true)}
          </Dropdown>
        ) : (
          <CSSTransition
            in={isBagIconAnimating}
            timeout={1000}
            classNames="bounce"
            onEntered={toggleAnimation}
          >
            {getBagIcon()}
          </CSSTransition>
        )}
      </Widget>
    );
  };

  return (
    <ul className={styles.container} data-testid="widgets">
      {searchWidget()}
      {myAccountWidget()}
      {savedItemsWidget()}
      {bagWidget()}
    </ul>
  );
};

Widgets.propTypes = {
  countryCode: PropTypes.string.isRequired,
  defaultStoreUrl: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  getSavedItemsFromSDK: PropTypes.func.isRequired,
  hasSavedItems: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isUpdated: PropTypes.bool,
  keyStoreDataversion: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  myAccountOpen: PropTypes.bool,
  miniBagOpen: PropTypes.bool,
  noOfBagItems: PropTypes.number,
  openSearch: PropTypes.func.isRequired,
  addLastFocusElement: PropTypes.func.isRequired,
  storeCode: PropTypes.string.isRequired,
  viewport: viewportPropType,
  currentEvent: PropTypes.string
};

export default withTranslation(Widgets);
