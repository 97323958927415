import getCategoryDetailsIfNeeded from "./getCategoryDetailsIfNeeded";
import getSequentialData from "./getSequentialData";

const getParallelData = ({
  dispatch,
  store,
  query,
  country,
  language,
  uuid,
  xSiteOrigin,
  isNewPage,
  shouldReloadCategoryDetails,
  refineForCategoryDetails,
  decisions
}) =>
  Promise.all([
    getCategoryDetailsIfNeeded({
      dispatch,
      store,
      query,
      country,
      language,
      uuid,
      xSiteOrigin,
      isNewPage,
      shouldReloadCategoryDetails,
      refineForCategoryDetails
    }),
    getSequentialData({
      dispatch,
      store,
      query,
      country,
      language,
      uuid,
      xSiteOrigin,
      isNewPage,
      decisions
    })
  ]);

export default getParallelData;
