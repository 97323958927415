import { createAction } from "redux-actions";
import {
  CATEGORY_DETAILS_LOADED,
  CATEGORY_LOADED,
  CURATED_CATEGORY_TREATMENTS_LOADED
} from "../constants";
import logger from "../../../../lib/logger";
import { getCategoryDetails, getCategory } from "../../../../lib/api/category";
import { getCuratedCategoryTreatments } from "../../../../lib/api/curatedCategory";
import getDisplayableChildLinkCountForCategory from "./getDisplayableChildLinkCountForCategory";
import { getCuratedCategoryChannel } from "../../config/selectors";

export function loadCategoryDetails({
  country,
  language,
  query,
  uuid,
  xSiteOrigin,
  loyaltyTier
}) {
  return dispatch =>
    getCategoryDetails({
      country,
      language,
      query,
      uuid,
      xSiteOrigin,
      loyaltyTier
    })
      .then(response => dispatch(categoryDetailsLoaded(response)))
      .catch(async error => {
        error.message = `getCategoryDetails failed, and caught error: ${error.message} `;
        logger.error(error);
        await dispatch(categoryDetailsLoaded(error));

        throw error;
      });
}

export function loadCategory({ country, language, query, uuid, xSiteOrigin }) {
  return dispatch => {
    const displayableChildLinkCount = getDisplayableChildLinkCountForCategory({
      categoryId: query.cid
    });

    return getCategory({
      country,
      language,
      query,
      uuid,
      xSiteOrigin,
      displayableChildLinkCount
    })
      .then(response => dispatch(categoryLoaded(response)))
      .catch(async error => {
        error.message = `getCategory failed, and caught error: ${error.message}`;
        logger.error(error);

        await dispatch(categoryLoaded(error));

        throw error;
      });
  };
}

export function loadCuratedCategoryTreatments({ query, country }) {
  return (dispatch, getState) => {
    const state = getState();
    const channel = getCuratedCategoryChannel(state);

    getCuratedCategoryTreatments({ query, country, channel })
      .then(treatments => dispatch(curatedCategoryTreatmentsLoaded(treatments)))
      .catch(async error => {
        error.message = `getCuratedCategoryTreatments failed, and caught error: ${error.message}`;
        logger.error(error);

        await dispatch(curatedCategoryTreatmentsLoaded());
      });
  };
}

export const categoryDetailsLoaded = createAction(
  CATEGORY_DETAILS_LOADED,
  response => ({
    ...response.categoryDetails
  })
);

export const categoryLoaded = createAction(CATEGORY_LOADED, response => ({
  ...(SERVER
    ? {
        gender: require("../../../../server/render/common/breadcrumb").getGenderFromBreadcrumb(
          response.categories.breadcrumb
        )
      }
    : {}),
  ...response.categories
}));

export const curatedCategoryTreatmentsLoaded = createAction(
  CURATED_CATEGORY_TREATMENTS_LOADED,
  treatments => treatments
);
