const getMediaUrls = categoryId => ({
  mobile: {
    imageUrl: `https://content.asos-media.com/-/media/images/curated-categories/featured-header/${categoryId}-mobile.jpg`,
    videoUrl: `https://www.asos-video.com/video/upload/t_curated_category_mobile/_content_${categoryId}-mobile_Flash9_870xAuto_2500K.mp4`
  },
  desktop: {
    imageUrl: `https://content.asos-media.com/-/media/images/curated-categories/featured-header/${categoryId}-desktop.jpg`,
    videoUrl: `https://www.asos-video.com/video/upload/t_curated_category_desktop/_content_${categoryId}-desktop_Flash9_2560xAuto_5000K.mp4`
  }
});

export default getMediaUrls;
