const COMING_SOON_COPY_KEYS = {
  intro: "plp_banner_header_coming_soon",
  description: "plp_banner_description_coming_soon",
  ribbon: "coming_soon_label"
};

const EARLY_ACCESS_COPY_KEYS = {
  intro: "plp_banner_header_early_access",
  description: "plp_banner_description_early_access",
  ribbon: "early_access_label"
};

const getCopyKeys = isComingSoon =>
  isComingSoon ? COMING_SOON_COPY_KEYS : EARLY_ACCESS_COPY_KEYS;

export default getCopyKeys;
