import getPositionInItemsArrayFromPositionInProductTilesArray from "./getPositionInItemsArrayFromPositionInProductTilesArray";
import getAddittionalSpaceOccupiedByFeaturedTiles from "./getAdditionalSpaceOccupiedByFeaturedTiles";

const getAdditionalItemPosition = state => ({
  products,
  productTiles,
  viewportCategory
}) => {
  const getUnadjustedPosition = getPositionInItemsArrayFromPositionInProductTilesArray(
    productTiles
  );

  const getPositionAdjustment = getAddittionalSpaceOccupiedByFeaturedTiles({
    products,
    state,
    viewportCategory
  });

  return ({ configuredPosition, items }) => {
    /*  These virtual products are necessary if configured position
        is greater than the number of product tiles.

        Let's assume an element is configured to be at row 10,
        indicating position 40 in a 4-column layout, but we only have 32 products.

        Without virtual products, we'd assume that we can place the element at row 8 at most,
        so its positiong would be capped at 32.

        However, it is possible to have 10 or more rows with 32 products
        if some of them are 2x1 or 2x2 products.

        To account for this, we add virtual products to the end of the list,
        to fill up the "missing rows" and allow the element to be placed at row 10.

        The algorithm will then adjust the position of the element
        to account for the 2x1 and 2x2 tiles

        If the element still cannot be placed at its desired row, it's up to the consuming code
        to decide how to handle this case. */

    const virtualProducts = new Array(
      Math.max(configuredPosition - productTiles.length, 0)
    )
      .fill({})
      .map((_, index) => ({ id: `virtual product ${index}` }));

    const unadjustedPosition = getUnadjustedPosition({
      positionInProductTilesArray: configuredPosition,
      items,
      virtualProducts
    });

    const adjustedPosition =
      unadjustedPosition -
      getPositionAdjustment({
        endPosition: configuredPosition,
        virtualProducts
      });

    return adjustedPosition;
  };
};

export default getAdditionalItemPosition;
