import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { generateVideoUrl } from "./generateVideoUrl";
import { generatePosterUrl } from "./generatePosterUrl";
import usePauseVideo from "./usePauseVideo";
import useAutoPlay from "./useAutoPlay";
import Icon from "../Icon";
import { SMALL_VIEWPORT } from "../../state/modules/layout/constants";
import styles from "./index.css";

const CategoryHeaderVideo = ({ viewportCategory, mediaUrls }) => {
  const [showIcon, setShowIcon] = useState(true);
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [shouldVideoStayPaused, setShouldVideoStayPaused] = useState(true);

  const videoRef = useRef(null);

  const autoPlay = useAutoPlay({
    videoRef,
    setIsVideoPaused,
    setShouldVideoStayPaused
  });

  usePauseVideo({
    isMediumViewportOrGreater: viewportCategory !== SMALL_VIEWPORT,
    videoRef,
    shouldVideoStayPaused
  });

  const togglePlayPause = () => {
    videoRef.current.paused
      ? videoRef.current.play()
      : videoRef.current.pause();

    setIsVideoPaused(prevState => !prevState);
    setShouldVideoStayPaused(prevState => !prevState);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      togglePlayPause();
    }
  };

  const handleError = () => {
    setShowIcon(false);
  };

  return (
    <div className={styles.categoryHeaderVideo}>
      <video
        ref={videoRef}
        key={viewportCategory}
        className={styles.video}
        loop
        muted
        autoPlay={autoPlay}
        playsInline
        src={generateVideoUrl(viewportCategory, mediaUrls)}
        poster={generatePosterUrl(viewportCategory, mediaUrls)}
        onError={handleError}
      ></video>
      <div className={styles.videoOverlay}>
        {showIcon && (
          <button
            className={styles.overlayButtonOutline}
            onClick={togglePlayPause}
            onKeyDown={handleKeyPress}
            aria-label={isVideoPaused ? "Play" : "Pause"}
          >
            <Icon
              icon={isVideoPaused ? "play" : "pause"}
              className={styles.icon}
            />
          </button>
        )}
      </div>
    </div>
  );
};

CategoryHeaderVideo.propTypes = {
  viewportCategory: PropTypes.string.isRequired,
  mediaUrls: PropTypes.shape({
    mobile: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired
    }).isRequired,
    desktop: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default CategoryHeaderVideo;
