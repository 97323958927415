const getMediaUrls = headerContent => {
  const {
    web: { video: desktopVideoUrl, image: desktopImageUrl },
    apps: { video: mobileVideoUrl, image: mobileImageUrl }
  } = headerContent;

  return {
    mobile: {
      imageUrl: mobileImageUrl,
      videoUrl: mobileVideoUrl
    },
    desktop: {
      imageUrl: desktopImageUrl,
      videoUrl: desktopVideoUrl
    }
  };
};

export default getMediaUrls;
