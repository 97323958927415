import getClientConfigValue from "../client/getClientConfigValue";
import { config as clientConfig } from "./client";
import toList from "../helpers/toList";
import { EU_OMNIBUS_COUNTRIES_KEY, DEAL_BANNER_STORES_KEY } from "./constants";

const config = {
  ...clientConfig,
  api: {
    product: {
      search: {
        timeout: SERVER
          ? parseInt(process.env.SEARCH_API_TIMEOUT)
          : parseInt(getClientConfigValue("SEARCH_API_TIMEOUT")) || 5000,
        retries:
          (SERVER
            ? process.env.SEARCH_API_RETRIES
            : getClientConfigValue("SEARCH_API_RETRIES")) || 0,
        search: "v2/",
        categories: "v2/categories",
        curations: "v2/curations",
        recommendations: "v2/recommendations",
        suggestions: "v2/suggestions",
        pageSize: 72
      }
    },
    facet: {
      path: "/product/facet/v1/enrichments/",
      timeout: SERVER
        ? null
        : parseInt(getClientConfigValue("FACET_API_TIMEOUT")) || 5000
    },
    category: {
      path: "/product/category/v1/categories/",
      timeout:
        (SERVER
          ? process.env.CATEGORY_API_TIMEOUT
          : getClientConfigValue("CATEGORY_API_TIMEOUT")) || 5000,
      details: "/details",
      configuredDisplayableChildLinkCounts:
        (SERVER
          ? process.env.CLIENT_CONFIGURED_DISPLAYABLE_CHILD_LINK_COUNTS &&
            JSON.parse(
              process.env.CLIENT_CONFIGURED_DISPLAYABLE_CHILD_LINK_COUNTS
            )
          : JSON.parse(
              getClientConfigValue("CONFIGURED_DISPLAYABLE_CHILD_LINK_COUNTS")
            )) || {}
    },
    curatedCategory: {
      path: "/product/category-curation/v1/curations/",
      timeout:
        (SERVER
          ? process.env.CURATED_CATEGORY_API_TIMEOUT
          : getClientConfigValue("CURATED_CATEGORY_API_TIMEOUT")) || 5000,
      version: SERVER
        ? process.env.CLIENT_CURATED_CATEGORY_DOCUMENT_VERSION
        : getClientConfigValue("CURATED_CATEGORY_DOCUMENT_VERSION")
    },
    navigation: {
      baseUrl: SERVER
        ? process.env.NAVIGATION_API_HOSTNAME + "/navigation/"
        : "/api/fashion/navigation/",
      timeout:
        (SERVER
          ? process.env.NAVIGATION_API_TIMEOUT
          : getClientConfigValue("NAVIGATION_API_TIMEOUT")) || 5000,
      brands: "v2/tree/brands"
    },
    savedLists: {
      timeout:
        (SERVER
          ? null
          : parseInt(getClientConfigValue("SAVEDLISTS_API_TIMEOUT"))) || 7000
    },
    ...(SERVER
      ? {
          keepAlive: {
            freeSocketTimeout: process.env.API_KEEP_ALIVE_FREE_SOCKET_TIMEOUT,
            inactiveSocketTimeout:
              process.env.API_KEEP_ALIVE_INACTIVE_SOCKET_TIMEOUT
          }
        }
      : {})
  },

  imageGallerySwipeAnalytics: SERVER
    ? process.env.CLIENT_IMAGE_GALLERY_SWIPE_ANALYTICS
    : getClientConfigValue("IMAGE_GALLERY_SWIPE_ANALYTICS"),
  criteoPartnerIds: SERVER
    ? process.env.CLIENT_CRITEO_PARTNER_ID &&
      JSON.parse(process.env.CLIENT_CRITEO_PARTNER_ID)
    : getClientConfigValue("CRITEO_PARTNER_ID") &&
      JSON.parse(getClientConfigValue("CRITEO_PARTNER_ID")),
  [EU_OMNIBUS_COUNTRIES_KEY]: SERVER
    ? process.env.CLIENT_EU_OMNIBUS_COUNTRIES
    : getClientConfigValue("EU_OMNIBUS_COUNTRIES"),
  [DEAL_BANNER_STORES_KEY]: SERVER
    ? process.env.CLIENT_DEAL_BANNER_ENABLED_STORES
    : getClientConfigValue("DEAL_BANNER_ENABLED_STORES"),
  eventIconType: SERVER
    ? process.env.CLIENT_EVENT_ICON
    : getClientConfigValue("EVENT_ICON"),
  assetsBasePath: SERVER
    ? process.env.CLIENT_ASSETS_BASE_PATH
    : getClientConfigValue("ASSETS_BASE_PATH"),
  environmentMode:
    (SERVER
      ? process.env.CLIENT_ENVIRONMENT_MODE
      : getClientConfigValue("ENVIRONMENT_MODE")) || "prod",
  newFacets: toList(
    (SERVER
      ? process.env.CLIENT_NEW_FACETS
      : getClientConfigValue("CLIENT_NEW_FACETS")) || ""
  ),
  visualCategoryHeaderLinkStores: SERVER
    ? process.env.CLIENT_VISUAL_CATEGORY_HEADER_LINK_STORES
    : getClientConfigValue("VISUAL_CATEGORY_HEADER_LINK_STORES"),
  rankingDisclaimerLinks: SERVER
    ? process.env.CLIENT_RANKING_DISCLAIMER_LINKS &&
      JSON.parse(process.env.CLIENT_RANKING_DISCLAIMER_LINKS)
    : getClientConfigValue("RANKING_DISCLAIMER_LINKS") &&
      JSON.parse(getClientConfigValue("RANKING_DISCLAIMER_LINKS")),
  upsell: {
    carouselPosition: 24
  },
  ...((SERVER
    ? process.env.CLIENT_GOOGLE_AD_ENABLED
    : getClientConfigValue("GOOGLE_AD_ENABLED")) === "true"
    ? {
        adverts: {
          scriptSrc: SERVER
            ? process.env.CLIENT_GOOGLE_AD_MANAGER_SCRIPT_SRC
            : getClientConfigValue("GOOGLE_AD_MANAGER_SCRIPT_SRC"),
          adId: SERVER
            ? process.env.CLIENT_GOOGLE_AD_MANAGER_AD_ID
            : getClientConfigValue("GOOGLE_AD_MANAGER_AD_ID"),
          networkCodeAndEnvPrefix: SERVER
            ? process.env.CLIENT_GOOGLE_AD_MANAGER_NETWORK_CODE_AND_ENV_PREFIX
            : getClientConfigValue(
                "GOOGLE_AD_MANAGER_NETWORK_CODE_AND_ENV_PREFIX"
              ),
          fetchMarginPercent: parseInt(
            SERVER
              ? process.env.CLIENT_GOOGLE_AD_FETCH_MARGIN_PERCENT
              : getClientConfigValue("GOOGLE_AD_FETCH_MARGIN_PERCENT")
          ),
          renderMarginPercent: parseInt(
            SERVER
              ? process.env.CLIENT_GOOGLE_AD_RENDER_MARGIN_PERCENT
              : getClientConfigValue("GOOGLE_AD_RENDER_MARGIN_PERCENT")
          ),
          mobileMarginScaling: parseFloat(
            SERVER
              ? process.env.CLIENT_GOOGLE_AD_MOBILE_MARGIN_SCALING
              : getClientConfigValue("GOOGLE_AD_MOBILE_MARGIN_SCALING")
          ),
          adPosition: SERVER
            ? parseInt(process.env.CLIENT_GOOGLE_AD_POSITION)
            : parseInt(getClientConfigValue("GOOGLE_AD_POSITION")),
          targetingVicinity: SERVER
            ? parseInt(process.env.CLIENT_GOOGLE_AD_TARGETING_VICINITY)
            : parseInt(getClientConfigValue("GOOGLE_AD_TARGETING_VICINITY")),
          sizeMappings: [
            {
              viewPortSize: [970, 250],
              slotSizes: [[970, 250]]
            },
            {
              viewPortSize: [728, 90],
              slotSizes: [[728, 90]]
            },
            {
              viewPortSize: [0, 0],
              slotSizes: [[320, 100]]
            }
          ]
        }
      }
    : null)
};

export default config;
