import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../state/modules/layout/constants";

const mobilePosterUrlTemplateFactory = mediaUrls => mediaUrls.mobile.imageUrl;

const desktopPosterUrlTemplateFactory = mediaUrls => mediaUrls.desktop.imageUrl;

const VIEWPORT_CATEGORY_TO_POSTER_URL_TEMPLATE_FACTORY_MAP = {
  [SMALL_VIEWPORT]: mobilePosterUrlTemplateFactory,
  [MEDIUM_VIEWPORT]: desktopPosterUrlTemplateFactory,
  [LARGE_VIEWPORT]: desktopPosterUrlTemplateFactory
};

export const generatePosterUrl = (viewportCategory, mediaUrls) =>
  VIEWPORT_CATEGORY_TO_POSTER_URL_TEMPLATE_FACTORY_MAP[viewportCategory](
    mediaUrls
  );
