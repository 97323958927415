import getBadgeText from "./getBadgeText";
import { withTranslation } from "../../../translation";
import styles from "./index.css";
import classNames from "classnames";
import isComingSoon from "../../../../helpers/isComingSoon";
import PropTypes from "prop-types";

const EarlyAccessBadge = ({
  labels: { earlyAccessBadge: labelledById },
  earlyAccess,
  formatTranslation,
  className
}) => {
  if (!earlyAccess) {
    return null;
  }

  return (
    <div
      id={labelledById}
      className={classNames(className, {
        [styles.comingSoon]: isComingSoon({ earlyAccess })
      })}
    >
      {getBadgeText({ earlyAccess, formatTranslation })}
    </div>
  );
};

EarlyAccessBadge.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    earlyAccessBadge: PropTypes.string.isRequired
  }).isRequired,
  earlyAccess: PropTypes.shape({
    accessLevel: PropTypes.string.isRequired
  }),
  className: PropTypes.string
};

export default withTranslation(EarlyAccessBadge);
