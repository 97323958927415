import PropTypes from "prop-types";
import CategoryHeaderVideo from "../CategoryHeaderVideo";
import CategoryHeaderImage from "../CategoryHeaderImage";
import * as types from "./constants";

const CategoryHeaderMedia = ({ viewportCategory, mediaType, mediaUrls }) => {
  const CategoryHeaderMediaTypes = {
    image: CategoryHeaderImage,
    video: CategoryHeaderVideo
  };
  const Component = CategoryHeaderMediaTypes[mediaType];

  if (Component) {
    return (
      <Component viewportCategory={viewportCategory} mediaUrls={mediaUrls} />
    );
  }

  return null;
};

CategoryHeaderMedia.propTypes = {
  mediaType: PropTypes.oneOf(Object.values(types)).isRequired,
  viewportCategory: PropTypes.string.isRequired,
  mediaUrls: PropTypes.shape({
    mobile: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired
    }).isRequired,
    desktop: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default CategoryHeaderMedia;
