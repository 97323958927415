import { trigger } from "redial";
import { history } from "../../../client/history";
import {
  setPageName,
  setPageLoading,
  setIsServerRendered
} from "../../../state/modules/page/actions";
import clientSideRedirect from "../../../client/clientSideRedirect";
import { PAGE_NOT_FOUND } from "../../../state/modules/page/constants";
import getIsNewPage from "./getIsNewPage";
import matchRoutes from "../../../routes/matchRoutes";
import { clearCategoryError } from "../../../state/modules/category";
import getDataFetchingParameters from "./getDataFetchingParameters";
import { getCanonicalPath } from "../../../state/modules/router/selectors";
import { setupExperiments } from "../setupExperiments";
import { setNewSearch } from "../../../state/modules/search/actions";

let shortCircuit = false;
let lastKnownGoodLocation;

const setupRouteChangeListener = (store, decisions) => {
  lastKnownGoodLocation = history.location;
  history.listen(location => {
    if (shortCircuit || location.isPrefilterGenderedSearch) {
      return;
    }

    const { pathname, search: query } = location;

    const activeRoute = matchRoutes({ pathname });

    if (activeRoute.key === PAGE_NOT_FOUND) {
      clientSideRedirect({ response: { redirectUrl: `${pathname}${query}` } });
    } else if (activeRoute.key) {
      const { dispatch, getState } = store;
      const { category, search, page } = getState();
      const catId = category.details?.id;
      const searchTerm = search?.searchTerm;

      const isNewPage = getIsNewPage({ query, catId, searchTerm, page });

      activeRoute.props.component.load().then(loadedComponent => {
        const dataFetchingParameters = getDataFetchingParameters({
          store,
          location,
          dispatch,
          isNewPage,
          lastKnownGoodLocation,
          category,
          decisions
        });

        if (isNewPage) {
          dispatch(clearCategoryError());
          dispatch(setPageName(activeRoute.key));
          dispatch(setNewSearch());
          dispatch(setPageLoading(true));
        }

        dispatch(setIsServerRendered(false));

        setupExperiments(store, decisions);

        const { pathname: lastKnownGoodPathname } = lastKnownGoodLocation;

        trigger("fetch", loadedComponent.default, dataFetchingParameters).then(
          () => {
            const state = getState();
            const { category, error } = state;
            if (error) {
              shortCircuit = true;
              history.replace(lastKnownGoodLocation);
            }
            if (category.details?.id) {
              const canonicalPath = getCanonicalPath(state);

              if (lastKnownGoodPathname !== canonicalPath) {
                shortCircuit = true;
                history.replace({ ...location, pathname: canonicalPath });
              }
            }
            shortCircuit = false;
            lastKnownGoodLocation = history.location;
          }
        );
      });
    }
  });
};

export default setupRouteChangeListener;
