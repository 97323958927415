import PropTypes from "prop-types";
import CategoryHeaderMedia from "../CategoryHeaderMedia";
import CategoryHeaderCopy from "./CategoryHeaderCopy";
import { VIDEO_HEADER } from "../constants";
import { getHeaderTitle } from "../CategoryHeader/helpers";
import styles from "./CategoryHeaderHero.css";
import getMediaUrls from "./getMediaUrls";
import getMediaType from "./getMediaType";

const CategoryHeaderHero = ({ category, viewportCategory, isComingSoon }) => {
  const {
    headerTitle,
    friendlyName,
    headerHtmlEncoded,
    earlyAccess,
    headerContent
  } = category;
  const header = getHeaderTitle({
    headerTitle,
    friendlyName,
    headerHtmlEncoded
  });

  const mediaUrls = getMediaUrls(headerContent);
  const mediaType = getMediaType({ viewportCategory, mediaUrls });

  return (
    <div id={VIDEO_HEADER} className={styles.heroHeader}>
      <div className={styles.heroHeaderContainer}>
        <CategoryHeaderMedia
          mediaType={mediaType}
          viewportCategory={viewportCategory}
          mediaUrls={mediaUrls}
        />
      </div>
      <CategoryHeaderCopy
        header={header}
        isComingSoon={isComingSoon}
        endDate={earlyAccess.endDate}
      ></CategoryHeaderCopy>
    </div>
  );
};

CategoryHeaderHero.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    headerBody: PropTypes.string,
    friendlyName: PropTypes.string,
    headerHtmlEncoded: PropTypes.string,
    headerTitle: PropTypes.string,
    headerContent: PropTypes.object,
    earlyAccess: PropTypes.shape({
      endDate: PropTypes.string.isRequired
    })
  }).isRequired,
  viewportCategory: PropTypes.string.isRequired,
  isComingSoon: PropTypes.bool
};

export default CategoryHeaderHero;
