import { TILE_SIZES } from "../../helpers/constants";
import getProductTreatment from "../../helpers/getProductTreatment";
import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../state/modules/layout/constants";

const {
  TILE_SIZE_ONE_BY_ONE,
  TILE_SIZE_TWO_BY_ONE,
  TILE_SIZE_TWO_BY_TWO
} = TILE_SIZES;

const TILE_SIZE_TO_ADDITIONAL_SPACE_MAP = {
  [SMALL_VIEWPORT]: {
    [TILE_SIZE_ONE_BY_ONE]: 0,
    [TILE_SIZE_TWO_BY_ONE]: 1,
    [TILE_SIZE_TWO_BY_TWO]: 1
  },
  [LARGE_VIEWPORT]: {
    [TILE_SIZE_ONE_BY_ONE]: 0,
    [TILE_SIZE_TWO_BY_ONE]: 1,
    [TILE_SIZE_TWO_BY_TWO]: 3
  }
};

const getAddittionalSpaceOccupiedByFeaturedTiles = ({
  products,
  state,
  viewportCategory
}) => ({ endPosition, virtualProducts }) =>
  viewportCategory === MEDIUM_VIEWPORT
    ? 0
    : products
        .concat(virtualProducts)
        .slice(0, endPosition)
        .reduce((additionalSpace, product, index) => {
          if (index + additionalSpace >= endPosition) {
            return additionalSpace;
          }

          const treatment = getProductTreatment(
            { product, productIndex: index },
            state
          );

          if (!treatment) {
            return additionalSpace;
          }

          const { tileSize } = treatment;

          return (
            additionalSpace +
            TILE_SIZE_TO_ADDITIONAL_SPACE_MAP[viewportCategory][tileSize]
          );
        }, 0);

export default getAddittionalSpaceOccupiedByFeaturedTiles;
