import { withTogglePointFactory } from "@asosteam/asos-web-toggle-point-cuts";
import { pointCutPluginFactory } from "@asosteam/asos-web-toggle-point-optimizely";
import sdkInstanceProvider from "../../client/sdkProvider";
import logger from "../../lib/logger";
import { LOG_LEVEL_ERROR } from "../../lib/logger/constants";
import { getFeatures } from "../../featuresStore";

const plugin = pointCutPluginFactory({
  sdkInstanceProvider,
  getAudience: () => getFeatures()?.audience
});

const withTogglePoint = withTogglePointFactory({
  getActiveFeatures: () => getFeatures()?.decisions,
  logError: logger[LOG_LEVEL_ERROR],
  plugins: [plugin]
});

export default withTogglePoint;
