import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../state/modules/layout/constants";

const mobileVideoUrlTemplateFactory = mediaUrls => mediaUrls.mobile.videoUrl;

const desktopVideoUrlTemplateFactory = mediaUrls => mediaUrls.desktop.videoUrl;

const VIEWPORT_CATEGORY_TO_VIDEO_URL_TEMPLATE_FACTORY_MAP = {
  [SMALL_VIEWPORT]: mobileVideoUrlTemplateFactory,
  [MEDIUM_VIEWPORT]: desktopVideoUrlTemplateFactory,
  [LARGE_VIEWPORT]: desktopVideoUrlTemplateFactory
};

export const generateVideoUrl = (viewportCategory, mediaUrls) =>
  VIEWPORT_CATEGORY_TO_VIDEO_URL_TEMPLATE_FACTORY_MAP[viewportCategory](
    mediaUrls
  );
