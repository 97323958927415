import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./CategoryHeaderDescription.css";

const CategoryHeaderDescription = ({ viewport, description }) => {
  if (!description) {
    return null;
  }

  const [expanded, setExpanded] = useState(false);
  const [visibleText, setVisibleText] = useState("");

  useEffect(() => {
    setExpanded(false);
  }, [viewport]);

  useEffect(() => {
    setVisibleText(description.slice(0, viewport === "small" ? 50 : 85));
  }, [description, viewport]);

  const handleOnKeyDown = event =>
    event.code === "Enter" || event.code === " " ? setExpanded(!expanded) : "";

  return (
    <div className={styles.categoryHeaderDescription}>
      <div
        className={styles.button}
        onClick={() => setExpanded(!expanded)}
        onKeyDown={handleOnKeyDown}
        role="button"
        tabIndex="0"
        aria-controls="descriptionContainer"
        aria-expanded={expanded}
      >
        <div className={styles.container} id="descriptionContainer">
          <p
            className={classNames(styles.description, {
              [styles.descriptionExpanded]: expanded
            })}
          >
            <span>{expanded ? description : visibleText}</span>
            {!expanded && <span aria-hidden="true">...</span>}
          </p>
          <div
            className={classNames(styles.icon, {
              [styles.iconUp]: expanded
            })}
          />
        </div>
      </div>
    </div>
  );
};

CategoryHeaderDescription.propTypes = {
  description: PropTypes.string,
  viewport: PropTypes.string.isRequired
};

export default CategoryHeaderDescription;
