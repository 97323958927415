import PropTypes from "prop-types";
import styles from "./index.css";
import { decodeAndSortUrl } from "../../helpers/decodeAndSortUrl";

const range = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, index) => start + index);

const Pagination = ({ formatTranslation, currentPage, maxPage, url }) => {
  const MAX_LINKS = 20;
  const start = Math.max(1, currentPage - Math.floor(MAX_LINKS / 2));
  const end = Math.min(maxPage, currentPage + Math.floor(MAX_LINKS / 2));

  const adjustedStart = Math.max(2, end - MAX_LINKS);
  const adjustedEnd = Math.min(maxPage, start + MAX_LINKS);

  let pages = [];
  if (maxPage > 1) {
    pages = range(adjustedStart, adjustedEnd).filter(
      page => page !== currentPage
    );
    if (currentPage !== 1) {
      pages = [1, ...pages];
    }
  }

  return (
    <div className={styles.paginationLinks} data-nosnippet>
      {pages.map(page => (
        <a
          key={page}
          href={decodeAndSortUrl(`${url}${page === 1 ? "" : `&page=${page}`}`)}
        >
          {`${formatTranslation("plp_web_category_page")} ${page}`}
        </a>
      ))}
    </div>
  );
};

Pagination.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired
};

export default Pagination;
