import PropTypes from "prop-types";
import styles from "./index.css";
import CategoryHeaderLinks from "../CategoryHeader/CategoryHeaderLinks";
import CategoryHeaderHero from "./CategoryHeaderHero";
import { CATEGORY_BANNER_ID } from "../constants";
import * as types from "../CategoryHeaderMedia/constants";

export const CategoryHeader = ({
  hasHeaderLinks,
  category,
  mediaType,
  viewportCategory,
  isComingSoon
}) => (
  <section
    id={CATEGORY_BANNER_ID}
    className={styles.wrapper}
    aria-label={`category banner ${mediaType}`}
  >
    <div className={styles.textWrapper}>
      <CategoryHeaderHero
        category={category}
        mediaType={mediaType}
        viewportCategory={viewportCategory}
        isComingSoon={isComingSoon}
      />
      {hasHeaderLinks && <CategoryHeaderLinks />}
    </div>
  </section>
);

CategoryHeader.propTypes = {
  category: PropTypes.shape({
    headerBody: PropTypes.string,
    headerTitle: PropTypes.string
  }).isRequired,
  mediaType: PropTypes.oneOf(Object.values(types)).isRequired,
  hasHeaderLinks: PropTypes.bool.isRequired,
  viewportCategory: PropTypes.string.isRequired,
  isComingSoon: PropTypes.bool
};

export default CategoryHeader;
