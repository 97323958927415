import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { options } from "../queryParamsToOptions";
import { withTranslation } from "../../translation";
import { getLocation } from "../../../state/modules/router/selectors";
import { changeSort } from "../../../state/modules/search/actions";
import { getPageName } from "../../../state/modules/page/selectors";
import classnames from "classnames";
import styles from "./index.css";

const keyPresses = ["Enter", 13, " ", 32];

const handleChange = ({ changeSort, sortedBy, translatedLabel }) => () => {
  changeSort({ type: sortedBy, name: translatedLabel });
};

const handleClick = (changeHandler, close) => () => {
  changeHandler();
  close();
};

const handleKeyDown = changeHandler => event => {
  if (
    [event?.key, event?.which].some(keypress => keyPresses.includes(keypress))
  ) {
    event.preventDefault();
    changeHandler();
  }
};

/**
 * Firefox triggers the keyup and click event for spacebar clicks for accessibility reasons.
 *
 * REF: https://bugzilla.mozilla.org/show_bug.cgi?id=1487102
 *
 * If we were to call `close()` on keydown, the move of focus to the "sort" button would
 * result in the button receiving the keyup and click events - causing the dropdown to
 * reopen.
 *
 * Therefore we call `close()` on keyup so these events don't get re-assigned to the
 * "sort" button and are handled here instead.
 **/

const handleKeyUp = close => event => {
  if (
    [event?.key, event?.which].some(keypress => keyPresses.includes(keypress))
  ) {
    event.preventDefault();
    close();
  }
};

const selectedOption = (location, pageName) =>
  options(pageName).find(option => option.value === location.query.sort);

const isSelected = (location, pageName, label) =>
  label === selectedOption(location, pageName).label;

const setLiveText = (location, formatTranslation, pageName) => {
  if (location.query) {
    return formatTranslation("plp_web_a11y_sorted_by", {
      value: formatTranslation(selectedOption(location, pageName).label)
    });
  }
};

const sortedByLiveTextId = "sorted-by-live-text-id";

export const LargeScreenSort = ({
  location,
  formatTranslation,
  changeSort,
  close,
  pageName
}) => (
  <div>
    <span
      className={styles.screenReader}
      aria-live="polite"
      id={sortedByLiveTextId}
    >
      {setLiveText(location, formatTranslation, pageName)}
    </span>
    <ul
      className={styles.sortList}
      role="listbox"
      aria-labelledby={sortedByLiveTextId}
    >
      {options(pageName).map(({ value, label }) => {
        const translatedLabel = formatTranslation(label);

        return (
          <li
            id={label}
            key={`sort=${value}`}
            role="option"
            aria-selected={isSelected(location, pageName, label)}
            className={classnames(styles.sortItem, {
              [styles.current]: location.query && location.query.sort === value
            })}
            onKeyDown={handleKeyDown(
              handleChange({
                changeSort,
                sortedBy: value,
                translatedLabel
              })
            )}
            onKeyUp={handleKeyUp(close)}
            onClick={handleClick(
              handleChange({
                changeSort,
                sortedBy: value,
                translatedLabel
              }),
              close
            )}
            tabIndex={0}
          >
            <label htmlFor={value}>
              {translatedLabel}
              <input
                className={styles.radioButton}
                type="radio"
                name="sort"
                id={label}
                value={value}
              />
            </label>
          </li>
        );
      })}
    </ul>
  </div>
);

LargeScreenSort.displayName = "Sort";

LargeScreenSort.propTypes = {
  location: PropTypes.object.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  formatLink: PropTypes.func.isRequired,
  changeSort: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    location: getLocation(state),
    pageName: getPageName(state)
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSort
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(LargeScreenSort));
