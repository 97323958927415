import PropTypes from "prop-types";
import styles from "./Ribbon.css";
import classNames from "classnames";
import { withTranslation } from "../../translation";
import getCopyKeys from "./getCopyKeys";

export const BULLET_SYMBOL = " \u2022 ";

const Ribbon = ({ isComingSoon, formatTranslation }) => {
  const { ribbon } = getCopyKeys(isComingSoon);
  const ribbonText = formatTranslation(ribbon);

  return (
    <div
      aria-hidden="true"
      className={classNames("london4", styles.ribbon, {
        [styles.comingSoon]: isComingSoon
      })}
    >
      {new Array(50).fill(null).map((_, index) => (
        <span key={index}>
          {ribbonText}
          {BULLET_SYMBOL}
        </span>
      ))}
    </div>
  );
};

Ribbon.propTypes = {
  isComingSoon: PropTypes.bool,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(Ribbon);
